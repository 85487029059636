import React, { Component } from "react"

class AmazonScript extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const s = document.createElement("script")
    s.type = "text/javascript"
    s.async = true
    s.innerHTML = `var _pix = document.getElementById('_pix_id_ac67f9a8-1f8f-3a2c-12e9-83b3fc3f496c');if (!_pix) { var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 'aax-eu.amazon-adsystem.com/s/iu3?d=generic&ex-fargs=%3Fid%3Dac67f9a8-1f8f-3a2c-12e9-83b3fc3f496c%26type%3D55%26m%3D3&ex-fch=416613&ex-src=https://www.blistex.co.uk/&ex-hargs=v%3D1.0%3Bc%3D3433503400302%3Bp%3DAC67F9A8-1F8F-3A2C-12E9-83B3FC3F496C' + '&cb=' + a); _pix.setAttribute('id','_pix_id_ac67f9a8-1f8f-3a2c-12e9-83b3fc3f496c'); document.body.appendChild(_pix);}`
    this.instance.appendChild(s)
  }

  render() {
    return <div ref={el => (this.instance = el)} />
  }
}

export default AmazonScript
