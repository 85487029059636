import React from "react"
import { Link } from "gatsby"
import footerLogo from "../../images/footer-logo.svg"

const Footer = () => {
  const toggleStockists = () => {
    const overlay = document.querySelector(".overlay")
    const stockistsCheckbox = document.getElementById("stockists")
    const stockists = document.querySelector(".stockists")
    const header = document.querySelector(".header-hero-container")
    if (stockistsCheckbox.checked) {
      stockistsCheckbox.checked = !stockistsCheckbox.checked
      overlay.classList.remove("overlay--show")
      stockists.style.paddingTop = ""
      stockists.style.paddingBottom = ""
      if (header) {
        header.style.overflow = "hidden"
        header.style.zIndex = "1"
      }
    } else {
      stockistsCheckbox.checked = !stockistsCheckbox.checked
      overlay.classList.add("overlay--show")
      stockists.style.paddingTop = "30px"
      stockists.style.paddingBottom = "30px"
      if (header) {
        header.style.overflow = "visible"
        header.style.zIndex = "auto"
      }
    }
  }
  return (
    <footer className="blistex-footer">
      <nav className="blistex-footer__terms-links">
        <ul>
          <li>
            <Link to="/terms-and-conditions/">terms and conditions.</Link>
          </li>
          {/*
          <li>
            <Link to="/where-to-buy/">where to buy.</Link>
          </li> */}
          <li>
            <Link to="/cookies/">cookie.</Link>
          </li>
          <li>
            <Link to="/privacy-policy/">privacy policy.</Link>
          </li>
          <li>
            <Link to="/contact/">contact.</Link>
          </li>
        </ul>
      </nav>

      <nav className="blistex-footer__social-links">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/BlistexUK"
              target="_blank"
              rel="noopener noreferrer"
            >
              facebook.
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blistexuk/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram.
            </a>
          </li>
        </ul>
      </nav>
      <a href="/">
        <img src={footerLogo} alt="Blistex" className="blistex-footer__logo" />
      </a>
    </footer>
  )
}

export default Footer
