import React, { Component } from "react"
import PropTypes from "prop-types"
import logo from "../../images/blistex-logo.svg"
import whiteLogo from "../../images/white-logo.svg"
import plus from "../../images/icons/plus.svg"
import plusWhite from "../../images/icons/plus-white.svg"
import { Link } from "gatsby"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popoutMenuOpen: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  onComponentDidMount() {
    this.setState({ popoutMenuOpen: false })
    const overlay = document.querySelector(".overlay")
    overlay.classList.remove("overlay--show")
  }

  togglePopoutMenu() {
    this.setState({ popoutMenuOpen: !this.state.popoutMenuOpen })
    const popoutMenu = document.querySelector(".popout-menu")

    if (!this.state.popoutMenuOpen === true) {
      if (popoutMenu) {
        popoutMenu.classList.add("active")
        disableBodyScroll(popoutMenu)
      }
    } else {
      if (popoutMenu) {
        popoutMenu.classList.remove("active")
        enableBodyScroll(popoutMenu)
      }
    }
  }

  handleClick() {
    const overlay = document.querySelector(".overlay")
    const header = document.querySelector(".header-hero-container")
    overlay.classList.toggle("overlay--show")
    if (!header) return
    if (overlay.classList.contains("overlay--show")) {
      header.style.zIndex = "auto"
    } else {
      header.style.zIndex = "1"
    }
  }

  render() {
    return (
      <>
        <header
          className={
            "container-left container-right" +
            (this.state.popoutMenuOpen === true ? " header--show-bun" : "")
          }
        >
          <nav className={"menu menu--main"}>
            <div
              className="menu__logo"
              style={{ zIndex: "92", transform: "translate3d(0,0,0)" }}
            >
              <a href="/">
                {this.state.popoutMenuOpen === true && (
                  <img src={whiteLogo} alt="Blistex Logo" />
                )}

                {this.state.popoutMenuOpen === false && (
                  <img src={logo} alt="Blistex Logo" />
                )}
              </a>
            </div>
            <div className="menu__links">
              <Link to="/products/" className="menu__links--range">
                <img src={plus} className="menu__links-icon" alt="" />
                <img
                  src={plusWhite}
                  className="menu__links-icon menu__links-icon--white"
                  alt=""
                />
                view the range
              </Link>

              <input type="checkbox" name="stockists" id="stockists" />
              <Link to="/where-to-buy/" className="menu__links--range">
                <img src={plus} className="menu__links-icon" alt="" />
                <img
                  src={plusWhite}
                  className="menu__links-icon menu__links-icon--white"
                  alt=""
                />
                stockists
              </Link>

              <div
                className="menu__links--stockists"
                onClick={() => {
                  this.togglePopoutMenu()
                }}
              >
                <ul
                  className={
                    "buns" + (this.state.popoutMenuOpen ? " toggled" : "")
                  }
                >
                  <li className="bun"></li>
                  <li className="bun"></li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
