import React, { Component } from "react"

class GoogleEvents extends Component {
  componentDidMount() {
    if (window.gtag) {
      window.gtag("event", "conversion", {
        allow_custom_scripts: true,
        send_to: "DC-9758210/homep0/blist0+standard",
      })
    }
  }
  render() {
    return (
      <noscript>
        <img
          src="https://ad.doubleclick.net/ddm/activity/src=9758210;type=homep0;cat=blist0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?"
          width="1"
          height="1"
          alt=""
        />
      </noscript>
    )
  }
}

export default GoogleEvents
