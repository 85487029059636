/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import GoogleEvents from "./googleEvents"
import PopoutMenu from "./popoutMenu"
import AmazonScript from "../amazonScript"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleOverlayClick = () => {
    const overlay = document.querySelector(".overlay")
    const stockistsCheckbox = document.getElementById("stockists")
    const stockists = document.querySelector(".stockists")
    const header = document.querySelector(".header-hero-container")

    stockists.style.paddingTop = ""
    stockists.style.paddingBottom = ""
    stockistsCheckbox.checked = !stockistsCheckbox.checked
    overlay.classList.remove("overlay--show")

    if (!header) return
    header.style.overflow = "hidden"
    header.style.zIndex = "1"
  }

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="AcceptedCookies"
        style={{
          background: "#ee1944",
          color: "#ffffff",
          fontFamily: "avant-garde-blistex-std",
          alignItems: "center",
        }}
        buttonStyle={{
          color: "#000000",
          background: "#ffffff",
          fontSize: "15px",
          fontFamily: "avant-garde-blistex-demi",
          padding: "10px 20px 8px",
        }}
        expires={150}
      >
        <span
          style={{
            color: "#ffffff",
            fontFamily: "avant-garde-blistex-std",
            lineHeight: "1.3",
          }}
        >
          We use technology, such as cookies, to personalize and enhance your
          experience. By continuing to use this site, you agree to our use of
          cookies.{" "}
        </span>
        <span
          style={{
            color: "#ffffff",
            fontFamily: "avant-garde-blistex-std",
            fontSize: "12px",
            marginLeft: "15px",
            display: "inline-block",
          }}
        >
          <Link
            to="/cookies/"
            style={{
              color: "#ffffff",
              textDecoration: "underline",
            }}
          >
            Find out more
          </Link>
        </span>
      </CookieConsent>
      <GoogleEvents />
      <PopoutMenu />
      <div className="overlay" onClick={handleOverlayClick}></div>
      <div
        className={
          "wrapper " +
          (props.makeFooterSticky === true ? "wrapper--sticky" : "") +
          (props.whiteMenu === true ? " white" : "")
        }
      >
        {props.noHeaderInLayout === false && (
          <Header siteTitle={data.site.siteMetadata.title} />
        )}
        <main>{props.children}</main>
        <AmazonScript />
        <noscript>
          {`<img
          height="1"
          width="1"
          border="0"
          alt=""
          src="https://aax-eu.amazon-adsystem.com/s/iui3?d=forester-did&ex-fargs=%3Fid%3Dac67f9a8-1f8f-3a2c-12e9-83b3fc3f496c%26type%3D55%26m%3D3&ex-fch=416613&ex-src=https://www.blistex.co.uk/&ex-hargs=v%3D1.0%3Bc%3D3433503400302%3Bp%3DAC67F9A8-1F8F-3A2C-12E9-83B3FC3F496C"
        />`}
        </noscript>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  noHeaderInLayout: false,
  makeFooterSticky: true,
}

export default Layout
