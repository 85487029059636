import React, { Component } from "react"
import lipBrilliance from "../../images/product-and-package/lb.jpg"
import ultra from "../../images/product-and-package/ul.jpg"
import intensiveMoisturiser from "../../images/product-and-package/inm.jpg"
import intensiveMoisturiserCherry from "../../images/product-and-package/mc.jpg"
import coldSoreCream from "../../images/product-and-package/cold-sore-cream-updated.jpg"
import blistexReliefCream from "../../images/product-and-package/relief-cream-2.jpg"
import medPlus from "../../images/product-and-package/mp.jpg"
import medPlusStick from "../../images/product-and-package/mp-stick.jpg"
import infusionsNourish from "../../images/product-and-package/lip-infusions-nourish.jpg"
import infusionsHydration from "../../images/product-and-package/lip-infusions-hydration.jpg"
import infusionsSoothing from "../../images/product-and-package/lip-infusions-soothing.jpg"
import infusionsRestore from "../../images/product-and-package/lip-infusions-restore.jpg"

class PopoutMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="popout-menu">
        <nav>
          <ul>
            <li className="large">
              <a href="/products">view the range.</a>
              <ul className="popout-menu__hover">
                {/* <li className="medium">
                  <a href="/lip-brilliance/">Lip Brilliance.</a>
                  <a className="product-image" href="/lip-brilliance/">
                    <img src={lipBrilliance} />
                  </a>
                </li> */}
                <li className="medium">
                  <a href="/ultra-lip-balm/">Ultra SPF 50+.</a>
                  <a href="/ultra-lip-balm/" className="product-image">
                    <img src={ultra} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/intensive-moisturiser/">Intensive Moisturiser.</a>
                  <a className="product-image" href="/intensive-moisturiser/">
                    <img src={intensiveMoisturiser} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/intensive-moisturiser-cherry/">
                    Intensive Moisturiser Cherry.
                  </a>
                  <a
                    className="product-image"
                    href="/intensive-moisturiser-cherry/"
                  >
                    <img src={intensiveMoisturiserCherry} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/cold-sore-cream/">Cold Sore Cream.</a>
                  <a href="/cold-sore-cream/" className="product-image">
                    <img src={coldSoreCream} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/blistex-relief-cream/">Blistex Relief Cream.</a>
                  <a href="/blistex-relief-cream/" className="product-image">
                    <img src={blistexReliefCream} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/MedPlus/">MedPlus Jar.</a>
                  <a href="/medplus/" className="product-image">
                    <img src={medPlus} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/medplus-stick/">MedPlus Stick.</a>
                  <a href="/medplus-stick/" className="product-image">
                    <img src={medPlusStick} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/lip-infusions-restore/">Lip Infusions - Restore.</a>
                  <a className="product-image" href="/lip-infusions-restore/">
                    <img src={infusionsRestore} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/lip-infusions-hydration/">
                    Lip Infusions - Hydration.
                  </a>
                  <a className="product-image" href="/lip-infusions-hydration/">
                    <img src={infusionsHydration} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/lip-infusions-soothing/">
                    Lip Infusions - Soothing.
                  </a>
                  <a className="product-image" href="/lip-infusions-soothing/">
                    <img src={infusionsSoothing} />
                  </a>
                </li>
                <li className="medium">
                  <a href="/lip-infusions-nourish/">Lip Infusions - Nourish.</a>
                  <a className="product-image" href="/lip-infusions-nourish/">
                    <img src={infusionsNourish} />
                  </a>
                </li>
              </ul>
            </li>
            <li className="large">
              <a href="/where-to-buy/">where to buy.</a>
            </li>
            <li className="large">
              <a href="/faqs/">FAQs.</a>
            </li>
            <li className="large">
              <a href="/winter-lips/">learn.</a>
              <ul className="popout-menu__hover">
                <li className="medium">
                  <a href="/winter-lips/">winter lips that conquer the cold.</a>
                </li>
                <li className="medium">
                  <a href="/wind-burn/">windburn.</a>
                </li>
                <li className="medium">
                  <a href="/sun-protection/">sun protection.</a>
                </li>
              </ul>
            </li>
            <li className="large">
              <a href="/blistex-brand-story/">our brand story.</a>
            </li>
            <li className="large">
              <a href="/contact/">contact.</a>
            </li>
            <li className="small">
              <a href="/terms-and-conditions/">terms and conditions.</a>
            </li>
            <li className="small">
              <a href="/privacy-policy/">privacy policy.</a>
            </li>
            <li className="small">
              <a href="/cookies/">cookie.</a>
            </li>
            <li className="small bold">
              <a
                href="https://www.facebook.com/BlistexUK"
                rel="noopener noreferrer"
                target="_blank"
              >
                facebook.
              </a>
              <a
                href="https://www.instagram.com/blistexuk/?hl=en"
                rel="noopener noreferrer"
                target="_blank"
              >
                instagram.
              </a>
              <a
                href="https://twitter.com/blistexuk"
                rel="noopener noreferrer"
                target="_blank"
              >
                twitter.
              </a>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

export default PopoutMenu
